import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';

import CaseStudyAttributions from '../../../../components/CaseStudyAttributions';
import Breadcrumb from '../../../../components/Breadcrumb';
import {graphql, useStaticQuery} from 'gatsby';
const AgricultureMonitoringForCommonPolicyPage = ({pageContext}) => {
	const caseStudyAttributions = {
		website: {
			href: 'http://esa-sen4cap.org',
			text: 'esa-sen4cap.org',
		},
		period: pageContext.caseStudy.period,
		region: 'Czechia, Italy, Lithuania, Netherlands, Romania, Spain, France',
		client: {
			href: 'http://www.esa.int/',
			text: 'European Space Agency (ESA)',
		},
		organisation: 'National Paying Agencies',
		projectLeader: 'Université Catholique de Louvain (Belgium)',
	};
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/work/caseStudies/water.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="EO based agricultural monitoring for Common Agricultural Policy" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>
					EO based agricultural monitoring for Common Agricultural Policy
				</HighlightedTitle>
				<HighlightedParagraph>
					Sentinels for Common Agriculture Policy (Sen4CAP)
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#cap'}>Common Agricultural Policy</a>
							<a href={'#project'}>The Sen4CAP project</a>
							<a href={'#our-part'}>Our part</a>
						</Anchors>

						<PersonBadge
							note={pageContext.caseStudy.contactNote}
							email={pageContext.caseStudy.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light className={'gst-attribution-block'}>
				<Content>
					<CaseStudyAttributions {...caseStudyAttributions} />
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'cap'}>Common Agricultural Policy</h2>
					<p>
						The Common Agricultural Policy (CAP) of the European Union aims at
						improving the European agricultural productivity in a sustainable
						manner while ensuring a decent standard of living of farmers within
						the EU.
					</p>
					<p>
						With an annual budget of roughly €59 billion, the CAP strengthens
						the competitiveness and sustainability of agriculture in Europe by a
						range of measures including direct payments, market measures and
						rural development. The largest part of the CAP budget is managed and
						controlled through its Integrated Administration and Control System
						(IACS) aiming to safeguard the CAP financials and supporting the
						farmers to submit their declarations. The IACS is implemented at
						national level through Paying Agencies of each EU member state.
						Within the on-going process of CAP reform the Earth Observation (EO)
						takes an increasing role for improving the IACS and making it more
						cost efficient.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'project'}>The Sen4CAP project</h2>
					<p>
						The Sen4CAP project provided to the European and national
						stakeholders of the CAP validated algorithms, products, workflows
						and best practices for agriculture monitoring relevant for the
						management of the CAP.
					</p>
					<p>
						The following products have been developed within the project:
						Cultivated crop type map, Grassland mowing product, Vegetation
						status indicator, Agricultural practices monitoring product,
						together with the Interactive visualization services for satellite
						imagery and use-case products. These products were built on an
						integrated use of both Sentinel-1 and Sentinel-2 data. Their
						performance has been validated against in-situ data through the
						prototyping and demonstration project activities.
					</p>
					<p>
						The project paid particular attention to provide evidence how
						Sentinel derived information can support the modernization and
						simplification of the CAP in the post 2020 timeframe. In parallel,
						the open-source processing system was developed and validated. The
						demonstration phase of the project also included the training and
						capacity building activities for the transfer of the developed EO
						products and services to the Paying Agencies.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'our-part'}>Our part</h2>
					<p>
						Gisat has been responsible for development, testing and validation
						of methods for agricultural practices monitoring. It included crop
						harvest and tillage detection, monitoring of fallow lands,
						catch-crops and nitrogen-fixing crops. The resulting products have
						been demonstrated and validated in collaboration with Paying
						Agencies of 7 pilot countries.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

AgricultureMonitoringForCommonPolicyPage.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default AgricultureMonitoringForCommonPolicyPage;
